<!-- Iremos colocar aqui todos os cadastros complementares do sisteam. para deixar a equipe mais independete. 
    Ex: Conselho, Modalidade, -->

<template>
    <v-row>
    </v-row>
</template>

<script>

export default {

}

</script>

<style>

</style>